.container {
    max-width: 1280px !important;
    padding-left: 20px;
    padding-right: 20px;
}

.animate {
    opacity: 0;
}

/** Header Video **/
header {
    position: relative;
    background-color: black;
    height: 50vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
}

header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

header .container {
    position: relative;
    z-index: 2;
}

header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
}

/** Support Popup Modal **/

.option-box {
    border: 1px solid #ddd;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    width: 45%;
    border-radius: 5px;
    background-color: #f8f9fa;
}
.option-box:hover {
    background-color: #e9ecef;
}
.option-box.selected {
    background-color: #ffc107;
    color: #fff;
    border-color: #ffc107;
}

/** Banner **/

.navbar-brand {
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.search-container {
    text-align: center;
}

.search-form {
    display: inline-block;
}

.nav-menu {
    margin-left: auto;
}

.banner-title {
    color: white;
    font-weight: 600;
    font-size: 40px;
}

.banner-desc {
    color: white;
}

.form-inline .form-control {
    width: 460px;
}

.nav-link {
    text-wrap: nowrap;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
}

.hero-container {
    background-image: url(/images/banner.png?215dfeb5e689a1fca6ca2f7bdc5a4240);
    background-size: cover;
    background-position: center;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-input {
    width: 100%;
    max-width: 500px;
}

/** Event item **/

.ticket-icon {
    width: 50px;
    margin-right: 10px;
}

.ticket-text {
    font-size: 12px;
    white-space: normal;
    line-height: normal;
}

.event-item {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
}
.event-item img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 350px;
    object-fit: cover;
    transition: transform ease-in 300ms;
}
#event-box:hover > .event-item img {
    transform: scale(1.05);
}
#event-box {
    box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.02);
}

.event-label {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ffc107;
    padding: 5px 10px;
    border-radius: 5px;
}

.event-stock-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: lightgreen;
    color: green;
    padding: 5px 10px;
    border-radius: 5px;
}

.event-stock-label.waiting-list {
    background-color: #ffcccc; /* Light red background */
    color: #cc0000; /* Dark red text */
}


.event-stock-label.low-stock {
    background-color: lightyellow; /* Light red background */
    color: orange; /* Dark red text */
}

.event-single-stock-label {
    background-color: lightgreen;
    color: green;
    padding: 5px 10px;
    border-radius: 5px;
    width: fit-content;
    margin-bottom: -10px;
}

.event-single-stock-label.waiting-list {
    background-color: #ffcccc; /* Light red background */
    color: #cc0000; /* Dark red text */
}

.event-single-stock-label.low-stock {
    background-color: lightyellow; /* Light red background */
    color: orange; /* Dark red text */
}

.event-favorite i {
    color: #fff;
}
.category-label {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: #ffc107;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}
.event-info {
    padding: 10px;
}
.event-info .row {
    margin-bottom: 5px;
}
.event-image {
    height: 100% !important;
    object-fit: cover !important;
}
.main-bg {
    background-color: #f1f5f8;
}

.event-detail {
    font-size: 16px;
    font-weight: 600;
    background: #0014611a;
    padding: 10px 12px;
    border-radius: 10px;
    text-align: center;
}
.event-title {
    font-size: 36px;
    font-weight: 700;
}

.sub-event-title {
    font-size: 22px;
    font-weight: 600;
}

.sub-subheadline {
    font-size: 18px;
    font-weight: 600;
}

.event-banner {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.see-more-btn {
    text-align: center;
    margin-top: 20px;
}
.see-more-btn button {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.btn-orange {
    background-color: #ffc107;
    border-color: #ffc107;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    height: calc(2.75rem + 2px);
    font-weight: 700;
}

.btn-orange:hover {
    background-color: #ffcd38;
    border-color: #ffcd38;
    box-shadow: 0px 2px 10px 0px rgba(250, 180, 0, 0.5);
}

.seatplan-container {
    margin: auto;
    width: max-content;
    background: beige;
    padding: 20px 50px;
    border-radius: 10px;
}

.seatplan-container-canvas,
.seatplan-container-canvas > .container {
    width: 100%;
    max-width: 100% !important;
}

.promo-code-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

#seats_container {
    position: relative;
    border-radius: 0 !important;
    background-color: #ffffff;
    background-size: 0.5rem 0.5rem;
    background-position: 0.25rem 0.25rem;
    background-image: linear-gradient(
            to right,
            rgba(50, 100, 150, 0.1) 1px,
            transparent 1px
        ),
        linear-gradient(to bottom, rgba(50, 100, 150, 0.1) 1px, transparent 1px);
    margin: 0;
}

#seats_container::before,
#seats_container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: 2.5rem 2.5rem;
    background-position: 0.25rem 0.25rem;
    background-image: linear-gradient(
            to right,
            rgba(50, 100, 150, 0.1) 2px,
            transparent 2px
        ),
        linear-gradient(to bottom, rgba(50, 100, 150, 0.1) 2px, transparent 2px);
    z-index: -1;
}

#seats_container::after {
    background-size: 5rem 5rem;
    background-image: linear-gradient(
            to right,
            rgba(50, 100, 150, 0.1) 3px,
            transparent 3px
        ),
        linear-gradient(to bottom, rgba(50, 100, 150, 0.1) 3px, transparent 3px);
}

/*# sourceMappingURL=site.css.map*/

/* Checkout */

.form-control {
    background-color: #f1f5f8;
    height: calc(2em + 0.75rem + 2px);
}

label {
    margin-bottom: 0.1rem;
    font-size: 14px;
}

.form-group {
    margin-bottom: 0.6rem;
}

.checkout-subtitle {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 6px;
}

.co-price-details {
    font-size: 14px;
}

.co-price-text {
    font-size: 16px;
    font-weight: 600;
}

.step-indicator {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
}
.step-indicator div {
    font-weight: 600;
    flex: 1;
    text-align: center;
    padding: 18px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}
.step-indicator .active {
    background-color: #ffc107;
    color: #fff;
    border-color: #ffc107;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
}
.order-summary {
    background-color: #f1f5f8;
    border: 1px solid #ced4da;
    padding: 20px;
    border-radius: 5px;
}
.order-summary .ticket-warning {
    background-color: #000;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #ffc107;
}
.payment-method {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}
.payment-method .payment-box {
    flex: 1;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    color: #b0b0b0 !important;
}
.payment-method .selected {
    background-color: #ffc107;
    box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
}
.payment-method .disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}
.btn-buy,
.btn-continue {
    margin-top: 20px;
    font-weight: 600;
    width: 100%;
    background-color: #001f3f;
    color: white;
    border: none;
    background-color: #ffc107;
    border-color: #ffc107;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    height: calc(2.75rem + 2px);
}
.confirmation {
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
}
.confirmation h4 {
    margin-bottom: 20px;
}
.confirmation .btn-group {
    display: flex;
    justify-content: center;
}
.confirmation .btn-group .btn {
    margin: 5px;
}

/* Responsive */
@media (max-width: 480px) {
    header {
        height: 35vh;
        min-height: 22rem;
    }
    header video {
        max-width: unset;
        width: 660px;
    }
    .navbar-dark .navbar-nav .nav-link {
        font-size: 16px;
    }
    .event-banner {
        gap: 10px;
    }
    .event-image {
        max-height: 300px;
    }
    .event-title {
        font-size: 26px !important;
    }
    .hero-container {
        height: 200px;
    }
    .payment-method {
        margin-top: 05px;
    }
    .event-item img {
        height: 250px;
    }
    .step-indicator {
        margin-bottom: 10px;
    }
    .step-indicator div {
        padding: 18px 10px;
        font-size: 14px;
    }
    .payment-method {
        margin-top: 05px;
        flex-direction: column;
    }
    .ticket-text {
        max-width: 160px;
        line-height: normal;
        font-size: 10px;
        text-wrap: wrap;
    }
    .event-detail {
        padding: 10px 10px;
        font-size: 14px;
    }
    #helpButton .help-text {
        display: none;
    }
    #helpButton {
        font-size: 25px;
    }
    .option-box {
        width: auto;
    }
    .event-single-stock-label {
        margin-bottom: 0px;
    }
}
